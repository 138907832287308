import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Yu Chen | TaroDev', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my website!', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Yu Chen',
  subtitle: 'I love code!',
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'Hello I\'m Yu, I go by Kevin and Taro. I am a Computer Science graduate and software engineer that enjoy building applications that are helpful.',
  paragraphTwo: 'On my free time I like to go on long walks, watch tv shows, build custom keyboards, and play video games.',
  paragraphThree: 'Some of my technological skills include:',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'lamp.jpg',
    title: 'NASDAQ Web Scraper',
    info: 'An application created using the lamp stack with python.',
    info2: 'Webscraper for NASDAQ about the top 100 stocks with their information and changes throughout the day. The bash script scrapes every hour and updates the database.',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'c++.png',
    title: 'Interpreter',
    info: 'C++ based interpreter for a Turing Complete language with a tokenizer and a recursive descent parser',
    info2: '',
    url: '',
    repo: 'https://github.com/TaroBubble/cs280-interpreter', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'snake.jpg',
    title: 'TaroBot',
    info: 'Discord bot made for moderating servers and simplifying commands for server owners.',
    info2: 'General use bot to simplify managing channels, creating channels, giving roles, and more!',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'I would love to hear about job opportunities!',
  btn: 'Contact me and let\'s talk',
  email: 'yuchen9610@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/yu-chen-911aaa182/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/TaroBubble',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
